import { useState } from 'react';

import { useSelector } from 'react-redux';

import { TabContext, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Tab } from '@mui/material';
import usePermissions from 'api/usePermissions';
import { EPermissionGroup } from 'common/permissions.types';
import { AuthSliceState } from 'store/store.types';

import useIntegration from 'api/useIntegration';
import usePublisher from 'api/usePublisher';
import { EPublisherSolutionModel, User } from 'common/contracts';
import { ELocalStorageKeys, ENotificationType } from 'constants/enums';
import { useNotifications } from 'hooks/useNotifications';
import { localStorageUtil } from 'utils/localStorageUtil';
import { permissionsUtil } from 'utils/permissionsUtil';
import AcContentWrapper from '../AcContentWrapper/AcContentWrapper';
import AcTabs from '../AcTabs/AcTabs';
import AcViewWrapper from '../AcViewWrapper/AcViewWrapper';
import PageTopBar from '../Topbar/PageTopBar';

import CompanySettings from './CompanySettings/CompanySettings';
import IntegrationSettings from './IntegrationSettings/IntegrationSettings';
import PaymentSettings from './PaymentSettings/PaymentSettings';
import PlayerAuthSettings from './PlayerAuthSettings/PlayerAuthSettings';
import { IntegrationSettingsData } from './Settings.types';
import StoreSettings from './Settings/StoreSettings';
import StoreLayout from './StoreLayout/StoreLayout';
import UsersSettings from './UsersSettings/UsersSettings';

import './style.scss';

enum ESettingsState {
  COMPANY = 'company',
  USERS = 'users',
  INTEGRATION = 'integration',
  PLAYER_AUTH = 'playerAuth',
  PAYMENTS = 'payments',
  ENCRYPTION = 'encryption',
  LAYOUT = 'layout',
  SETTINGS = 'settings'
}

const SettingsView = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const projectsDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.projects;
  const { getPublisher, updatePublisher } = usePublisher(
    currentPublisherId || undefined
  );
  const { getIntegration, updateIntegration, getPublisherEvents }: any =
    useIntegration(currentPublisherId);
  const { enqueueSnackbar } = useNotifications();
  const { getPermissions } = usePermissions();
  const permissions = getPermissions();

  const handleAuthTabShown = () => {
    const projectItem = projectsDetails?.find(
      (item) => item.publisherId === currentPublisherId
    );
    const isCheckout = projectItem?.projectType === EPublisherSolutionModel.CHECKOUT

    return (
      !isCheckout ||
      permissionsUtil.shouldHideGroupInPermission(
        permissions,
        EPermissionGroup.AUTHENTICATION,
        getPublisher.data?._id
      )
    );
  };

  const [tab, setTab] = useState(
    permissionsUtil.shouldHideGroupInPermission(
      permissions,
      EPermissionGroup.COMPANY,
      getPublisher.data?._id
    )
      ? ESettingsState.COMPANY
      : permissionsUtil.shouldHideGroupInPermission(
            permissions,
            EPermissionGroup.INTEGRATION,
            getPublisher.data?._id
          )
        ? ESettingsState.INTEGRATION
        : permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.USERS,
              getPublisher.data?._id
            )
          ? ESettingsState.USERS
          : handleAuthTabShown()
            ? ESettingsState.PLAYER_AUTH
            : ESettingsState.COMPANY
  );

  const savePublisherDetails = (companyData: any) => {
    updatePublisher.mutate(companyData, {
      onSuccess: () => {
        getPublisher.refetch();
        enqueueSnackbar(
          'Company details updated successfully',
          ENotificationType.SUCCESS
        );
      },
      onError: (data: any) => {
        enqueueSnackbar(data.response.data.message, ENotificationType.ERROR);
      }
    });
  };

  const savePlayersAuthenticationDetails = (
    playersAuthenticationValues: Partial<IntegrationSettingsData>
  ) => {
    updateIntegration.mutate(playersAuthenticationValues, {
      onSuccess: () => {
        getPublisher.refetch();
        enqueueSnackbar(
          'Facebook Authentication details updated successfully',
          ENotificationType.SUCCESS
        );
      },
      onError: (data: any) => {
        enqueueSnackbar(
          'Failed to update Facebook Authentication details',
          ENotificationType.ERROR
        );
      }
    });
  };

  const saveWebhooksDetails = (
    webhookValues: Partial<IntegrationSettingsData>
  ) => {
    updateIntegration.mutate(webhookValues, {
      onSuccess: () => {
        getPublisher.refetch();
        enqueueSnackbar(
          'Webhook details updated successfully',
          ENotificationType.SUCCESS
        );
      },
      onError: (data: any) => {
        enqueueSnackbar(
          'Failed to update Webhook details',
          ENotificationType.ERROR
        );
      }
    });
  };

  const handleSettingsTabShown = () => {
    return currentPublisherId
      ? permissionsUtil.isSuperAdminByProject(currentPublisherId)
      : false;
  };

  return (
    <AcViewWrapper
      header={
        <>
          <PageTopBar headline="Admin" withTabsDesign={true} />
          <Box pl={'3rem'} pr={'3rem'}>
            <AcTabs
              value={tab}
              onChange={(_, newValue) => {
                getIntegration.refetch();
                setTab(newValue);
              }}
              aria-label="basic tabs example"
              sx={{
                borderBottom: '1px solid #757575'
              }}
            >
              {!permissionsUtil.shouldHideGroupInPermission(
                permissions,
                EPermissionGroup.COMPANY,
                getPublisher.data?._id
              ) && <Tab label="Company" value={ESettingsState.COMPANY} />}
              {!permissionsUtil.shouldHideGroupInPermission(
                permissions,
                EPermissionGroup.USERS,
                getPublisher.data?._id
              ) && <Tab label="Users" value={ESettingsState.USERS} />}
              {!permissionsUtil.shouldHideGroupInPermission(
                permissions,
                EPermissionGroup.INTEGRATION,
                getPublisher.data?._id
              ) && (
                <Tab label="Integration" value={ESettingsState.INTEGRATION} />
              )}
              {handleAuthTabShown() && (
                <Tab
                  label="Authentication"
                  value={ESettingsState.PLAYER_AUTH}
                />
              )}
              {handleSettingsTabShown() && (
                <Tab label="Payments" value={ESettingsState.PAYMENTS} />
              )}
              {handleSettingsTabShown() && (
                <Tab label="Store Layout" value={ESettingsState.LAYOUT} />
              )}
              {handleSettingsTabShown() && (
                <Tab label="Store Settings" value={ESettingsState.SETTINGS} />
              )}
            </AcTabs>
          </Box>
        </>
      }
    >
      <AcContentWrapper>
        <TabContext value={tab}>
          <TabPanel value={ESettingsState.COMPANY} sx={{ paddingX: 0 }}>
            {getPublisher.data && !getPublisher.isLoading ? (
              <CompanySettings
                data={getPublisher.data}
                savePublisherDetails={savePublisherDetails}
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 'calc(100vh - 340px)',
                  width: '100%'
                }}
              >
                <CircularProgress title="Loading..." />
              </div>
            )}
          </TabPanel>
          <TabPanel
            value={ESettingsState.USERS}
            sx={{ paddingX: 0, paddingTop: 0, marginTop: -2 }}
          >
            <UsersSettings />
          </TabPanel>
          <TabPanel value={ESettingsState.INTEGRATION} sx={{ paddingX: 0 }}>
            <IntegrationSettings
              integrationRefetch={getIntegration.refetch}
              data={getIntegration?.data}
              events={getPublisherEvents.data}
              savePlayersAuthenticationDetails={
                savePlayersAuthenticationDetails
              }
              saveWebhooksDetails={saveWebhooksDetails}
            />
          </TabPanel>
          <TabPanel value={ESettingsState.PLAYER_AUTH} sx={{ paddingX: 0 }}>
            <PlayerAuthSettings data={getIntegration?.data} />
          </TabPanel>
          {permissionsUtil.isSuperAdmin() && (
            <TabPanel value={ESettingsState.PAYMENTS} sx={{ paddingX: 0 }}>
              <PaymentSettings />
            </TabPanel>
          )}
          <TabPanel value={ESettingsState.LAYOUT} sx={{ paddingX: 0 }}>
            <StoreLayout />
          </TabPanel>
          <TabPanel value={ESettingsState.SETTINGS} sx={{ paddingX: 0 }}>
            <StoreSettings />
          </TabPanel>
        </TabContext>
      </AcContentWrapper>
    </AcViewWrapper>
  );
};

export default SettingsView;
