import { SyntheticEvent, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Tab } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';
import PageTopBar from 'components/Topbar/PageTopBar';
import AcTabs from 'components/AcTabs/AcTabs';
import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import { useSelector } from 'react-redux';
import { AuthSliceState } from 'store/store.types';

enum EPlayersState {
    BLOCKED = 'blocked'
}

export const PlayersView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const [tab, setTab] = useState(EPlayersState.BLOCKED);

    const handleTabChange = (event:  SyntheticEvent, newValue: EPlayersState) => {
        setTab(newValue);
        const newPath = `/project/${currentPublisherId}/players/${newValue}`;
        navigate(newPath);
    }

    return (
      <AcViewWrapper
        header={
          <>
            <PageTopBar withTabsDesign={true} headline="Players" />
            <Box pl={'3rem'} pr={'3rem'}>
              <AcTabs value={tab} onChange={handleTabChange}>
                <Tab label="Blocked players" value={EPlayersState.BLOCKED} />
              </AcTabs>
            </Box>
          </>
        }
      >
        <AcContentWrapper>
          <TabContext value={tab}>
             <TabPanel value={tab} sx={{ padding: 0 }}>
               <Outlet />
             </TabPanel>
          </TabContext>
        </AcContentWrapper>
      </AcViewWrapper>
    )
}
