import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LoyaltyOutlined from '@mui/icons-material/LoyaltyOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import { EPermissionGroup, IPermissions } from 'common/permissions.types';

import { EPublisherSolutionModel, Project } from 'common/contracts';
import { EFeatureFlag, ELocalStorageKeys, ESideBarGroup } from 'constants/enums';
import { localStorageUtil } from 'utils/localStorageUtil';
import { permissionsUtil } from 'utils/permissionsUtil';

const getProjectsRoute = (project: Project) => {
  const isCheckoutProject =
    project.projectType === EPublisherSolutionModel.CHECKOUT;
  if (isCheckoutProject)
    return `${project.publisherId}/statistics/?range=2`;
  const canAccessAnalytics = permissionsUtil.canAccessAnalytics(
    localStorageUtil.get<IPermissions>(ELocalStorageKeys.PERMISSIONS) || {},
    project.publisherId
  );
  const canAccessBuilder = permissionsUtil.canAccessBuilder(
    localStorageUtil.get<IPermissions>(ELocalStorageKeys.PERMISSIONS) || {},
    project.publisherId
  );

  return canAccessBuilder
    ? `${project.publisherId}/theme/store`
    : canAccessAnalytics
      ? `${project.publisherId}/statistics/?range=2`
      : `${project.publisherId}/offers`;
};

export const getMainNavItems = (
  projectsDetails: Project[] | undefined,
  showReportsNotification: boolean,
  permissions: any
) => {
  if (projectsDetails) {
    const mainNavItems = [
      {
        title: 'Orders',
        icon: <CreditCardOutlinedIcon />,
        route: `orders`,
        group: ESideBarGroup.OVERVIEW,
        hide: permissionsUtil.getAccessPublisherOrders(permissions).length === 0
      },
      {
        title: 'Reports',
        icon: <TextSnippetOutlinedIcon />,
        route: `reports/financialreports`,
        group: ESideBarGroup.OVERVIEW,
        notification: showReportsNotification,
        hide:
          permissionsUtil.getPublishersByFinancialReports(permissions)
            .length === 0
      }
    ];

    const projectsNavItems = projectsDetails.map((item) => ({
      title: item.publisherName,
      icon: (
        <img className="project-logo" src={item.publisherLogo} alt="logo" />
      ),
      route: getProjectsRoute(item),
      group: ESideBarGroup.PROJECTS
    }));
    return mainNavItems.concat(projectsNavItems as any);
  }
  return [];
};

export const getSubNavItems = (
  activeProjectId: string,
  featureFlags?: Record<EFeatureFlag, boolean>,
  permissions?: any,
  isProjectCheckout?: boolean
) => {
  const popUpFeatureFlag = featureFlags?.[EFeatureFlag.DASHBOARD_POPUP];
  const couponsFeatureFlag = featureFlags?.[EFeatureFlag.DASHBOARD_COUPONS];

  return [
    {
      title: 'Analytics',
      icon: <InsertChartOutlinedIcon />,
      route: `${activeProjectId}/statistics`,
      defaultQuery: '/range=2',
      group: ESideBarGroup.MANAGEMENT,
      hide:
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.ANALYTICS,
              activeProjectId
          )
    },
    {
      title: 'Players',
      icon: <PersonOutlineIcon />,
      route: `${activeProjectId}/players/blocked`,
      group: ESideBarGroup.MANAGEMENT,
      hide:
          !featureFlags?.[EFeatureFlag.DASHBOARD_PLAYERS_LIST_BLOCKED] ||
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.PLAYER,
              activeProjectId
          )
    },
    {
      title: 'Segments',
      icon: <PieChartOutlineIcon />,
      route: `${activeProjectId}/segments`,
      group: ESideBarGroup.MANAGEMENT,
      hide:
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.SEGMENTS,
              activeProjectId
          ),
      disabled: isProjectCheckout
    },
    {
      title: 'Pricing',
      icon: <DiamondOutlinedIcon />,
      route: `${activeProjectId}/pricing`,
      group: ESideBarGroup.MANAGEMENT,
      hide:
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.PRICING,
              activeProjectId
          )
    },
    {
      title: 'Coupons',
      icon: <LocalActivityOutlinedIcon />,
      route: `${activeProjectId}/coupons`,
      group: ESideBarGroup.MANAGEMENT,
      hide: !couponsFeatureFlag
    },
    {
      title: 'Localization',
      icon: <LanguageIcon />,
      route: `${activeProjectId}/localization`,
      group: ESideBarGroup.MANAGEMENT,
      hide: !featureFlags?.[EFeatureFlag.STORE_MULTI_LANGUAGE_SUPPORT]
    },
    {
      // TODO: Change hide condition
      title: 'Settings',
      icon: <SettingsOutlinedIcon />,
      route: `${activeProjectId}/settings`,
      group: ESideBarGroup.MANAGEMENT,
      hide: !permissionsUtil.canAccessSettings(permissions, activeProjectId)
    },
    {
      title: 'Builder',
      icon: <StorefrontIcon />,
      route: `${activeProjectId}/theme/store`,
      group: ESideBarGroup.STUDIO,
      disabled: !(
        permissionsUtil.isSuperAdminByProject(activeProjectId || '') ||
        permissionsUtil.isAdminByProject(activeProjectId || '') ||
        permissionsUtil.canAccessBuilder(
          localStorageUtil.get<IPermissions>(ELocalStorageKeys.PERMISSIONS) ||
            {},
          activeProjectId || ''
        )
      ),
      hide:
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.BUILDER,
              activeProjectId
          )
    },
    {
      title: 'Products',
      icon: <LocalOfferOutlinedIcon />,
      route: `${activeProjectId}/products`,
      group: ESideBarGroup.STUDIO,
      disabled: isProjectCheckout,
      hide:
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.PRODUCTS,
              activeProjectId
          )
    },
    {
      title: 'Offers Design',
      icon: <WebAssetIcon />,
      route: `${activeProjectId}/offersui`,
      group: ESideBarGroup.STUDIO,
      disabled: isProjectCheckout,
      hide:
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.OFFER_DESIGN,
              activeProjectId
          )
    },
    {
      title: 'Badges',
      icon: <LoyaltyOutlined />,
      route: `${activeProjectId}/badges`,
      group: ESideBarGroup.STUDIO,
      disabled: isProjectCheckout,
      hide:
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.BADGES,
              activeProjectId
          )
    },
    {
      title: 'Assets Library',
      icon: <PhotoOutlinedIcon />,
      route: `${activeProjectId}/assets`,
      group: ESideBarGroup.STUDIO,
      disabled: isProjectCheckout,
      hide:
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.ASSET_LIBRARY,
              activeProjectId
          )
    },
    {
      title: 'Bundles',
      icon: <ShoppingBagOutlinedIcon />,
      route: `${activeProjectId}/offers`,
      group: ESideBarGroup.OFFERS,
      disabled: isProjectCheckout,
      hide:
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.BUNDLES,
              activeProjectId
          )
    },
    {
      title: 'Promotions',
      icon: <AccessAlarmIcon />,
      route: `${activeProjectId}/promotions`,
      group: ESideBarGroup.OFFERS,
      disabled: isProjectCheckout,
      hide:
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.PROMOTIONS,
              activeProjectId
          )
    },
    {
      title: 'Popups',
      icon: <EmojiEventsOutlinedIcon />,
      route: `${activeProjectId}/popups`,
      group: ESideBarGroup.OFFERS,
      hide:
          !popUpFeatureFlag ||
          permissionsUtil.shouldHideGroupInPermission(
              permissions,
              EPermissionGroup.PRODUCTS,
              activeProjectId
          ),
      disabled: isProjectCheckout
    }
  ];
};
