import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import {
  AddToHomeScreenButton,
  Background,
  createGradientOrSolid,
  overrideCssVars
} from '@appcharge/shared-ui';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';

import useImages from '../../../api/useImages';
import useUsers from '../../../api/useUsers';
import {
  EAssetType,
  EBundlesInternalViewModel,
  EFeatureFlag,
  ESectionViewModel
} from '../../../constants/enums';
import { UploadsTooltips, useUploads } from '../../../hooks/useUpload';
import { AuthSliceState } from '../../../store/store.types';
import { mapModelSection } from '../../../utils/mapModelSection';
import { permissionsUtil } from '../../../utils/permissionsUtil';
import AcCard from '../../AcCard/AcCard';
import AcGradientInput from '../../AcGradientInput/AcGradientInput';
import AcInput from '../../AcInput/AcInput';
import AcSelect from '../../AcSelect/AcSelect';
import ActionButton from '../../ActionButton/ActionButton';
import CustomizedSwitch from '../../SwitchButton/SwitchButton';
import { ThemeTabProps } from '../theme.types';

import StoreThemeBundles from './components/StoreThemeBundles';
import StoreThemeSectionsTable from './components/StoreThemeSectionsTable';
import { CurrentSectionItem, SectionsItem } from './storeTheme.types';
import { getFonts } from './utils';

import '../../../style/forms.scss';

export const StoreTheme: React.FC<ThemeTabProps> = ({
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  errors,
  touched,
  setTouched,
  validateField,
  sectionViewModel = ESectionViewModel.DEFAULT
}) => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const pictures = useImages(currentPublisherId).getImages;
  const addPictureInputRef = useRef<HTMLInputElement>(null);
  const [currentUploadingField, setCurrentUploadingField] = useState('');
  const [currentSection, setCurrentSection] =
    useState<CurrentSectionItem | null>(null);
  const [sections, setSections] = useState<SectionsItem[]>(
    values.general?.sections
  );
  const [editedSectionId, setEditedSectionId] = useState('');
  const { uploadImage } = useUploads();

  const [isProductMadness, setIsProductMadness] = useState<boolean>(false);
  useEffect(() => {
    const bundleViewModel = values?.general?.bundlesInternalViewModel;
    setIsProductMadness(
      [
        EBundlesInternalViewModel.JACKPOT_SLOTS,
        EBundlesInternalViewModel.BIG_FISH_CASINO
      ].includes(bundleViewModel)
    );
  }, [values.general.bundlesInternalViewModel]);

  const { fetchFeatureFlags } = useUsers({ publisherId: currentPublisherId });

  const showAddToHomeScreen: boolean = useMemo(
    () =>
      fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_ADD_TO_HOME_SCREEN
      ],
    [fetchFeatureFlags.data]
  );

  const showBannerSection: boolean = useMemo(
    () =>
      fetchFeatureFlags.data?.featureFlags?.[EFeatureFlag.DASHBOARD_BANNERS],
    [fetchFeatureFlags.data]
  );

  const cssMappingOverride = {
    '--button-text-color': values.general.buttonTextColor,
    '--button-color': createGradientOrSolid(values.general.buttonColor),
    '--bundle-border-color': createGradientOrSolid(
      values.storeScreen.bundleBorderColor
    )
  };

  const SectionLayout = mapModelSection[sectionViewModel as ESectionViewModel];

  const getSectionTableItems = () => {
    if (sections.length > 0) {
      return sections.map((item, index) => ({
        ...item,
        _id: item.publisherSectionId || index.toString(),
        type: 'Section'
      }));
    }
    return [];
  };

  useEffect(() => {
    if (values.general.sections.length > 0) {
      setSections(values.general.sections);
    }
  }, [values.general.sections]);

  overrideCssVars(cssMappingOverride, document);

  const handleChangeSectionsList = () => {
    return !!editedSectionId ? editSection() : addSection();
  };

  const addSection = () => {
    if (!currentSection) return;

    const sectionToAdd = {
      _id: currentSection.publisherSectionId,
      publisherSectionId: currentSection.publisherSectionId,
      name: currentSection.name,
      type: 'Section',
      image: currentSection?.image
    };
    setSections((prevSections) => [...prevSections, sectionToAdd]);

    const sectionFieldValue: SectionsItem = {
      _id: sectionToAdd.publisherSectionId || sectionToAdd.name,
      publisherSectionId: sectionToAdd.publisherSectionId,
      name: sectionToAdd.name,
      toAdd: true
    };
    if (sectionToAdd.image) {
      sectionFieldValue.image = sectionToAdd.image;
    }
    setFieldValue('general.sections', [
      ...values.general.sections,
      sectionFieldValue
    ]);
    setCurrentSection(null);
  };

  const editSection = () => {
    if (!currentSection) return;
    const updatedSectionsList = sections.map((section) => {
      if (section.publisherSectionId === editedSectionId) {
        const updatedSection: SectionsItem = {
          _id: currentSection.publisherSectionId,
          publisherSectionId: currentSection.publisherSectionId,
          sectionId: currentSection.sectionId,
          name: currentSection.name
        };
        if (currentSection.image) {
          updatedSection.image = currentSection.image;
        }
        return updatedSection;
      }
      return section;
    });
    setEditedSectionId('');
    setSections(updatedSectionsList);
    setFieldValue('general.sections', updatedSectionsList);
    setCurrentSection(null);
  };
  const getSectionImgItems = () => {
    if (!pictures.isLoading && pictures.data) {
      const filteredPic = pictures.data.filter(
        (p: any) => p.type === EAssetType.GENERAL
      );
      return filteredPic.length > 0
        ? filteredPic.map((picture: any) => ({
            content: picture.name,
            key: picture.url,
            value: picture.url,
            renderFunction: () => (
              <Stack
                className="formContent-imageSelected"
                direction="row"
                spacing={2}
              >
                <img alt={picture.name} src={picture.url} />
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: 0
                  }}
                >
                  {picture.name}
                </div>
              </Stack>
            )
          }))
        : [];
    }

    return [];
  };

  const sectionsData = sections.filter((section) => !section.toBeDeleted);

  return (
    <Grid container className="formContent">
      <Grid item xs={5}>
        {!pictures.isLoading && (
          <Stack>
            <AcCard
              stackContainer={false}
              title="Start by customizing your store"
              description="The brand assets you’ll add here will appear
                                across your web store. you can change them any
                                time."
            >
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcInput
                    header="Store Tab Title"
                    name="general.tabName"
                    value={values.general.tabName}
                    tooltip="This will be the title of your store tab."
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.tabName &&
                      Boolean(errors.general?.tabName)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    onChange={(e) =>
                      uploadImage(
                        e,
                        addPictureInputRef.current as HTMLInputElement,
                        pictures,
                        setFieldValue,
                        setCurrentUploadingField,
                        currentUploadingField
                      )
                    }
                    ref={addPictureInputRef}
                    accept="image/*"
                  />
                  <AcSelect
                    header="Logo"
                    name="general.logo"
                    value={values.general.logo}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue('general.logo', uploadData!.data.url);
                      },
                      uploadType: EAssetType.LOGO,
                      uploadMessage: UploadsTooltips[EAssetType.LOGO]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge logo',
                        key: 'https://media.appcharge.com/defaults/logo.svg',
                        value: 'https://media.appcharge.com/defaults/logo.svg',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/logo.svg"
                                alt="Default Appcharge logo"
                                style={{
                                  maxWidth: 26
                                }}
                              />
                              <Typography>Default Appcharge logo</Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.LOGO)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 26,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.logo && Boolean(errors.general?.logo)
                    }
                    tooltip="This will be the logo of your store."
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Favicon"
                    name="general.favicon"
                    value={values.general.favicon}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue('general.favicon', uploadData!.data.url);
                      },
                      uploadType: EAssetType.FAVICON,
                      uploadMessage: UploadsTooltips[EAssetType.FAVICON]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge favicon',
                        key: 'https://media.appcharge.com/defaults/logo.svg',
                        value: 'https://media.appcharge.com/defaults/logo.svg',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/logo.svg"
                                alt="Default Appcharge favicon"
                                style={{
                                  maxWidth: 30
                                }}
                              />
                              <Typography>Default Appcharge logo</Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.FAVICON)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.favicon &&
                      Boolean(errors.general?.favicon)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Background Image (Mobile)"
                    name="general.backgroundImageMobile"
                    value={values.general.backgroundImageMobile}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue(
                          'general.backgroundImageMobile',
                          uploadData!.data.url
                        );
                      },
                      uploadType: EAssetType.BG_MOBILE,
                      uploadMessage: UploadsTooltips[EAssetType.BG_MOBILE]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge background',
                        key: 'https://media.appcharge.com/defaults/background.png',
                        value:
                          'https://media.appcharge.com/defaults/background.png',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/background.png"
                                alt="Default Appcharge background"
                                style={{
                                  width: 30,
                                  maxHeight: 30
                                }}
                              />
                              <Typography>
                                Default Appcharge background
                              </Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.BG_MOBILE)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.backgroundImageMobile &&
                      Boolean(errors.general?.backgroundImageMobile)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Background Image (Desktop)"
                    name="general.backgroundImageDesktop"
                    value={values.general.backgroundImageDesktop}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue(
                          'general.backgroundImageDesktop',
                          uploadData!.data.url
                        );
                      },
                      uploadType: EAssetType.BG_DESKTOP,
                      uploadMessage: UploadsTooltips[EAssetType.BG_DESKTOP]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge background',
                        key: 'https://media.appcharge.com/defaults/background.png',
                        value:
                          'https://media.appcharge.com/defaults/background.png',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/background.png"
                                alt="Default Appcharge background"
                                style={{
                                  width: 30,
                                  maxHeight: 30
                                }}
                              />
                              <Typography>
                                Default Appcharge background
                              </Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.BG_DESKTOP)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.backgroundImageDesktop &&
                      Boolean(errors.general?.backgroundImageDesktop)
                    }
                  />
                </Grid>
                {isProductMadness && (
                  <Grid item xs={12}>
                    <AcSelect
                      header="Store Sale"
                      name="general.storeSaleImage"
                      value={values?.general?.storeSaleImage}
                      uploadConfig={{
                        onUploadSuccess: async (uploadData: any) => {
                          await pictures.refetch();
                          setFieldValue(
                            'general.storeSaleImage',
                            uploadData!.data.url
                          );
                        },
                        uploadType: EAssetType.GENERAL,
                        uploadMessage: UploadsTooltips[EAssetType.GENERAL]
                      }}
                      items={[
                        ...pictures.data
                          .filter((p: any) => p.type === EAssetType.GENERAL)
                          .map((picture: any) => {
                            return {
                              content: picture.name,
                              key: picture.url,
                              value: picture.url,
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src={picture.url}
                                      alt={picture.name}
                                      style={{
                                        width: 30,
                                        maxHeight: 30
                                      }}
                                    />
                                    <Typography>{picture.name}</Typography>
                                  </Stack>
                                );
                              }
                            };
                          })
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.general?.storeSaleImage &&
                        Boolean(errors.general?.storeSaleImage)
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </AcCard>
            <Divider />
            <AcCard
              stackContainer={false}
              title="Footer"
              description="These settings will apply  throughout your store"
            >
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcSelect
                    header="Footer Image (Mobile)"
                    name="general.footerImageMobile"
                    value={values.general.footerImageMobile}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue(
                          'general.footerImageMobile',
                          uploadData!.data.url
                        );
                      },
                      uploadType: EAssetType.LOGO,
                      uploadMessage: UploadsTooltips[EAssetType.LOGO]
                    }}
                    items={[
                      {
                        content: 'No image',
                        key: 'no-image',
                        value: '',
                        renderFunction: () => (
                          <Stack direction="row" alignItems="center" gap={1}>
                            <span
                              style={{
                                width: 30,
                                height: 30,
                                display: 'inline-block',
                                background: '#eee',
                                textAlign: 'center',
                                lineHeight: '30px'
                              }}
                            ></span>
                            <Typography>No Image</Typography>
                          </Stack>
                        )
                      },
                      {
                        content: 'Default Appcharge logo',
                        key: 'https://media.appcharge.com/defaults/logo.svg',
                        value: 'https://media.appcharge.com/defaults/logo.svg',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/logo.svg"
                                alt="Default Appcharge logo"
                                style={{
                                  maxWidth: 26
                                }}
                              />
                              <Typography>Default Appcharge logo</Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.LOGO)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.footerImageMobile &&
                      Boolean(errors.general?.footerImageMobile)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Footer Image (Desktop)"
                    name="general.footerImageDesktop"
                    value={values.general.footerImageDesktop}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue(
                          'general.footerImageDesktop',
                          uploadData!.data.url
                        );
                      },
                      uploadType: EAssetType.LOGO,
                      uploadMessage: UploadsTooltips[EAssetType.LOGO]
                    }}
                    items={[
                      {
                        content: 'No image',
                        key: 'no-image',
                        value: '',
                        renderFunction: () => (
                          <Stack direction="row" alignItems="center" gap={1}>
                            <span
                              style={{
                                width: 30,
                                height: 30,
                                display: 'inline-block',
                                background: '#eee',
                                textAlign: 'center',
                                lineHeight: '30px'
                              }}
                            ></span>
                            <Typography>No Image</Typography>
                          </Stack>
                        )
                      },
                      {
                        content: 'Default Appcharge logo',
                        key: 'https://media.appcharge.com/defaults/logo.svg',
                        value: 'https://media.appcharge.com/defaults/logo.svg',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/logo.svg"
                                alt="Default Appcharge logo"
                                style={{
                                  maxWidth: 26
                                }}
                              />
                              <Typography>Default Appcharge logo</Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.LOGO)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.footerImageDesktop &&
                      Boolean(errors.general?.footerImageDesktop)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Footer Appstore Link"
                    name="general.footerAppstoreLink"
                    value={values.general.footerAppstoreLink}
                    placeholder="https://www.apple.com/app-store/"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.footerAppstoreLink &&
                      Boolean(errors.general?.footerAppstoreLink)
                    }
                    helperText={
                      touched.general?.footerAppstoreLink
                        ? errors.general?.footerAppstoreLink?.toString()
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Footer GooglePlay Link"
                    name="general.footerGooglePlayLink"
                    value={values.general.footerGooglePlayLink}
                    placeholder="https://play.google.com/"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.footerGooglePlayLink &&
                      Boolean(errors.general?.footerGooglePlayLink)
                    }
                    helperText={
                      touched.general?.footerGooglePlayLink
                        ? errors.general?.footerGooglePlayLink?.toString()
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            <AcCard stackContainer={false} title="Sections">
              <Grid container rowSpacing={1.5} columnSpacing={1.5}>
                <Grid item xs={6}>
                  <AcInput
                    header="Section name"
                    name="sectionName"
                    value={currentSection?.name || ''}
                    tooltip="This will be the name of your section"
                    type="text"
                    onChange={(e) => {
                      setTouched({
                        ...touched,
                        sectionName: true
                      });
                      handleChange({
                        target: {
                          name: 'general.sectionName',
                          value: String(e.target.value)
                        }
                      });
                      setCurrentSection({
                        ...currentSection,
                        publisherSectionId:
                          currentSection?.publisherSectionId || '',
                        name: (e.target.value as string) || ''
                      });
                      validateField('general.sectionName');
                    }}
                    onBlur={handleBlur}
                    error={
                      touched?.sectionName &&
                      Boolean(errors?.general?.sectionName)
                    }
                    helperText={
                      touched?.sectionName
                        ? errors?.general?.sectionName?.toString()
                        : ''
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <AcInput
                    header="ID"
                    name="sectionId"
                    value={currentSection?.publisherSectionId || ''}
                    type="text"
                    onChange={(e) => {
                      setTouched({
                        ...touched,
                        sectionId: true
                      });
                      handleChange({
                        target: {
                          name: 'general.sectionId',
                          value: String(e.target.value)
                        }
                      });
                      setCurrentSection({
                        ...currentSection,
                        publisherSectionId: (e.target.value as string) || '',
                        name: currentSection?.name || ''
                      });
                      validateField('general.sectionId');
                    }}
                    onBlur={handleBlur}
                    error={
                      touched?.sectionId && Boolean(errors?.general?.sectionId)
                    }
                    helperText={
                      touched?.sectionId
                        ? errors?.general?.sectionId?.toString()
                        : ''
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <AcSelect
                    header="Image"
                    name="sectionImage"
                    value={currentSection?.image || ''}
                    onChange={(e) => {
                      setCurrentSection({
                        ...currentSection,
                        name: currentSection?.name || '',
                        image: e.target.value as string,
                        publisherSectionId:
                          currentSection?.publisherSectionId || ''
                      });
                    }}
                    onBlur={handleBlur}
                    onClear={() => {
                      if (currentSection) {
                        const { image, ...rest } = currentSection;
                        setCurrentSection(rest);
                      }
                    }}
                    disabled={pictures.isLoading || pictures.isRefetching}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setCurrentSection({
                          name: currentSection?.name || '',
                          image: uploadData!.data.url,
                          publisherSectionId:
                            currentSection?.publisherSectionId || ''
                        });
                      },
                      uploadType: EAssetType.GENERAL,
                      uploadMessage: UploadsTooltips[EAssetType.SECTION]
                    }}
                    items={getSectionImgItems()}
                    tooltip={
                      'For optimized display, make sure your image are at least 300px with 1:1 aspect ratio'
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <ActionButton
                    text={!!editedSectionId ? 'Save' : 'Add'}
                    variant="outlined"
                    disabled={
                      !currentSection?.publisherSectionId ||
                      !currentSection?.name
                    }
                    onClick={handleChangeSectionsList}
                  />
                </Grid>

                <Grid item xs={12} mt={-3}>
                  <StoreThemeSectionsTable
                    sections={getSectionTableItems()}
                    setSections={setSections}
                    setFieldValue={setFieldValue}
                    setCurrentSection={setCurrentSection}
                    setEditedSectionId={setEditedSectionId}
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            <AcCard stackContainer={false} title="Font">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcSelect
                    header="Font"
                    name="general.font"
                    value={values.general.font}
                    items={getFonts()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.font && Boolean(errors.general?.font)
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            <AcCard
              stackContainer={false}
              title="Store Buttons"
              description="These settings will apply to buttons throughout your store"
            >
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={6}>
                  <AcGradientInput
                    header="Button Color"
                    name="general.buttonColor"
                    defaultValue={values.general.buttonColor}
                    setValue={setFieldValue}
                    onBlur={handleBlur}
                    error={
                      touched.general?.buttonColor?.colorOne &&
                      Boolean(errors.general?.buttonColor?.colorOne)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <AcGradientInput
                    header="Button Text Color"
                    name="general.buttonTextColor"
                    defaultValue={{
                      colorOne: values.general.buttonTextColor
                    }}
                    setValue={setFieldValue}
                    onBlur={handleBlur}
                    onlySolid
                    outputAsSingleColor
                    error={
                      touched.general?.buttonTextColor &&
                      Boolean(errors.general?.buttonTextColor)
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
            {showAddToHomeScreen && (
              <>
                <Divider />
                <AcCard
                  stackContainer={false}
                  title="Add To Home Screen Button"
                >
                  <Grid container rowSpacing={2} columnSpacing={1.5}>
                    <Grid item xs={6}>
                      <AcGradientInput
                        header="Border and Text Color"
                        name="storeScreen.addToHomeScreen.borderColor"
                        defaultValue={
                          values.storeScreen.addToHomeScreen.borderColor
                            .colorOne
                            ? values.storeScreen.addToHomeScreen.borderColor
                            : {
                                colorOne: '#ffffff'
                              }
                        }
                        setValue={setFieldValue}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcGradientInput
                        header="Background Color"
                        name="storeScreen.addToHomeScreen.backgroundColor"
                        defaultValue={
                          values.storeScreen.addToHomeScreen.backgroundColor
                            ?.colorOne
                            ? values.storeScreen.addToHomeScreen.backgroundColor
                            : {
                                colorOne: '#522AD8',
                                colorTwo: '#7946FA'
                              }
                        }
                        setValue={setFieldValue}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </AcCard>
              </>
            )}
            <Divider />
            <AcCard stackContainer={false} title="Borders">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcGradientInput
                    header="Bundle Frame Color"
                    name="storeScreen.bundleBorderColor"
                    defaultValue={values.storeScreen.bundleBorderColor}
                    setValue={setFieldValue}
                    onBlur={handleBlur}
                    error={
                      touched.storeScreen?.bundleBorderColor &&
                      Boolean(errors.storeScreen?.bundleBorderColor)
                    }
                    helperText={
                      touched.storeScreen?.bundleBorderColor
                        ? errors.storeScreen?.bundleBorderColor?.toString()
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            {showBannerSection && (
              <>
                <AcCard stackContainer={false} title="Banner">
                  <Grid item xs={12}>
                    <AcSelect
                      header="Banner Image"
                      name="storeScreen.defaultBanner"
                      value={values?.storeScreen?.defaultBanner}
                      onClear={() => {
                        setFieldValue('storeScreen.defaultBanner', '');
                      }}
                      uploadConfig={{
                        onUploadSuccess: async (uploadData: any) => {
                          await pictures.refetch();
                          setFieldValue(
                            'storeScreen.defaultBanner',
                            uploadData!.data.url
                          );
                        },
                        uploadType: EAssetType.BANNER,
                        uploadMessage: UploadsTooltips[EAssetType.BANNER]
                      }}
                      items={[
                        ...pictures.data
                          .filter((p: any) => p.type === EAssetType.BANNER)
                          .map((picture: any) => {
                            return {
                              content: picture.name,
                              key: picture.url,
                              value: picture.url,
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src={picture.url}
                                      alt={picture.name}
                                      style={{
                                        width: 30,
                                        maxHeight: 30
                                      }}
                                    />
                                    <Typography>{picture.name}</Typography>
                                  </Stack>
                                );
                              }
                            };
                          })
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.storeScreen?.defaultBanner &&
                        Boolean(errors.storeScreen?.defaultBanner)
                      }
                    />
                  </Grid>
                </AcCard>
                <Divider />
              </>
            )}
            <AcCard stackContainer={false} title="Lack of Special Offers">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Lack of Special Offers Title Text"
                    name="storeScreen.noOffersTitleText"
                    value={values.storeScreen.noOffersTitleText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.storeScreen?.noOffersTitleText &&
                      Boolean(errors.storeScreen?.noOffersTitleText)
                    }
                    helperText={
                      touched.storeScreen?.noOffersTitleText
                        ? errors.storeScreen?.noOffersTitleText?.toString()
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Lack of Special Offers Message Text"
                    name="storeScreen.noOffersMessageText"
                    value={values.storeScreen.noOffersMessageText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.storeScreen?.noOffersMessageText &&
                      Boolean(errors.storeScreen?.noOffersMessageText)
                    }
                    helperText={
                      touched.storeScreen?.noOffersMessageText
                        ? errors.storeScreen?.noOffersMessageText?.toString()
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
            {permissionsUtil.isSuperAdmin() && (
              <>
                <Divider />
                <AcCard stackContainer={false} title="Privacy Policy and T&C">
                  <Grid container rowSpacing={2} columnSpacing={1.5}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography mr={4} fontSize={14} color="#717188">
                          Enable to open Privacy Policy and T&C in a new tab.
                          Disable to open in a modal window.
                        </Typography>
                        <CustomizedSwitch
                          texts={[]}
                          status={values.login.openLinksInNewTab}
                          functions={[
                            () =>
                              setFieldValue('login.openLinksInNewTab', false),
                            () => setFieldValue('login.openLinksInNewTab', true)
                          ]}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </AcCard>
              </>
            )}
          </Stack>
        )}
      </Grid>
      <Grid item xs={7} className="iphone-mock-wrapper">
        <div
          className="store-preview-wrapper iphone-mock"
          style={{ overflow: 'scroll' }}
        >
          {showAddToHomeScreen && (
            <AddToHomeScreenButton
              borderColor={
                values.storeScreen?.addToHomeScreen?.borderColor.colorOne
              }
              backgroundColor={
                values.storeScreen.addToHomeScreen.backgroundColor?.colorOne
                  ? values.storeScreen.addToHomeScreen.backgroundColor
                  : {
                      colorOne: '#522AD8',
                      colorTwo: '#7946FA'
                    }
              }
              onClick={() => ''}
              isMockPreview
            />
          )}

          <Background
            backgroundImageMobile={values.general.backgroundImageMobile}
            backgroundImageDesktop={values.general.backgroundImageMobile}
            width="100%"
            height="100%"
            position="absolute"
          />
          <img
            style={{ position: 'relative' }}
            alt="logo"
            className={`storeLogo ${values.general.logoSize}`}
            src={values.general.logo}
          />
          <div className="bundles-wrapper">
            {sectionsData.length > 0 ? (
              <div className="store-theme-sections">
                {sectionsData.map((section, index) => (
                  <>
                    <SectionLayout
                      key={section.publisherSectionId}
                      sectionName={section.name}
                      image={section.image}
                    />
                    <StoreThemeBundles values={values} />
                  </>
                ))}
              </div>
            ) : (
              <StoreThemeBundles values={values} />
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
