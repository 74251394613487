import { useState } from 'react';

import { useSelector } from 'react-redux';

import { TabContext, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Tab } from '@mui/material';

import useUsers from 'api/useUsers';
import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import AcTabs from 'components/AcTabs/AcTabs';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';
import PasswordTab from 'components/PasswordsTab/PasswordTab';
import PersonalInfo from 'components/PersonalInfo/PersonalInfo';
import PageTopBar from 'components/Topbar/PageTopBar';
import { ELocalStorageKeys, ENotificationType } from 'constants/enums';
import { useNotifications } from 'hooks/useNotifications';
import { AuthSliceState } from 'store/store.types';
import { localStorageUtil } from 'utils/localStorageUtil';

import { UpdateInfoData, UpdatePasswordData } from './ProfileView.types';

import './style.scss';

enum EProfileState {
  PERSONAL = 'personal',
  PASSWORD = 'password'
}

const ProfileView = () => {
  const { enqueueSnackbar } = useNotifications();
  const [tab, setTab] = useState(EProfileState.PERSONAL);
  const { getUsers, updateUser, updatePassword } = useUsers({
    shouldFetchUsersData: true,
    enableFeatureFlags: false
  });

  const userId = useSelector(
    ({ auth }: { auth: AuthSliceState }) =>
      auth.userId ||
      localStorageUtil.getAny(ELocalStorageKeys.USER_DETAILS)?.userId
  );
  const { isLoading } = getUsers;

  const changePassword = (passwordsData: UpdatePasswordData, cb: Function) => {
    passwordsData.userId = userId;
    updatePassword.mutate(passwordsData, {
      onSuccess: () => {
        getUsers.refetch();
        enqueueSnackbar(
          'Password updated successfully',
          ENotificationType.SUCCESS
        );
        cb();
      },
      onError: (data: any) => {
        enqueueSnackbar(data.response.data.message, ENotificationType.ERROR);
        cb();
      }
    });
  };

  const updateInfo = (
    data: Partial<UpdateInfoData>,
    cb?: Function,
    errorCb?: Function
  ) => {
    updateUser.mutate(data, {
      onSuccess: (data: any) => {
        enqueueSnackbar(
          `User info updated successfully`,
          ENotificationType.SUCCESS
        );
        cb?.();
      },
      onError: (data: any) => {
        enqueueSnackbar(data.response?.data?.message, ENotificationType.ERROR);
        errorCb?.();
      }
    });
  };

  if (isLoading)
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          height: '60px',
          width: '60px'
        }}
      />
    );

  return (
    <AcViewWrapper
      header={
        <>
          <PageTopBar headline="Profile" withTabsDesign={true} />
          <Box pl={'3rem'} pr={'3rem'}>
            <AcTabs
              value={tab}
              onChange={(_, newTab) => {
                setTab(newTab);
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Personal Info" value={EProfileState.PERSONAL} />
              <Tab label="Password" value={EProfileState.PASSWORD} />
            </AcTabs>
          </Box>
        </>
      }
    >
      <AcContentWrapper>
        <TabContext value={tab}>
          <TabPanel value={EProfileState.PERSONAL} sx={{ paddingX: 0 }}>
            <PersonalInfo userData={getUsers.data} updateInfo={updateInfo} />
          </TabPanel>
          <TabPanel value={EProfileState.PASSWORD} sx={{ paddingX: 0 }}>
            <PasswordTab changePassword={changePassword} />
          </TabPanel>
        </TabContext>
      </AcContentWrapper>
    </AcViewWrapper>
  );
};

export default ProfileView;
